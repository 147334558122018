export default {
  error_occurred: 'Съжаляваме, възникна грешка. Моля, опитайте отново след малко.',
  new_content_available: 'Има нова актуализация на страницата, моля, щракнете върху „Обнови“, за да презаредите страницата.',
  refresh_button_title: 'Опресняване',
  force_reload_message: 'Съжаляваме, възникна грешка. Нашият екип вече работи по него. Моля, опитайте да презаредите уебсайта след малко.',
  sign_up: 'Създай профил',
  service: 'Обслужване',
  employee: 'служител',
  select_employee: 'Изберете човек',
  employees: 'служители',
  services: 'Услуги',
  duration: 'Продължителност',
  from: 'От',
  select_date: 'Изберете дата',
  no_account_question: 'Все още нямате акаунт?',
  name: 'Първо име',
  surname: 'Фамилия',
  name_and_surname: 'Пълно име',
  phone: 'Телефон',
  password: 'Парола',
  field_is_required: 'Това поле е задължително.',
  value_is_too_long: 'Стойността е твърде дълга.',
  value_is_too_short: 'Стойността е твърде кратка.',
  signup_was_successful: 'Регистрацията беше успешна. Влизане в панела.',
  signup_has_failed: 'Регистрацията е неуспешна. Моля, опитайте отново след малко.',
  email_is_taken: 'Акаунтът с посочения имейл адрес вече съществува. Ако сте забравили паролата си, използвайте опцията за напомняне.',
  email_or_password_incorrect: 'Имейл адресът или паролата са неправилни. Ако сте забравили паролата си, използвайте опцията за напомняне.',
  account_not_found: 'Акаунтът не е намерен.',
  page_not_found: 'Опа! Страницата, която търсите, не може да бъде намерена :(',
  go_back_to_main_page: 'Върнете се на началната страница и опитайте отново.',
  bookings: 'Резервации',
  account: 'Моята сметка',
  date: 'Дата',
  cancel: 'Отказ',
  date_from: 'Дата от',
  date_to: 'Дата до',
  nothing_found_here: 'Нищо не беше намерено.',
  old_password: 'Стара парола',
  remove: 'Изтрий',
  phone_is_invalid: 'Телефонният номер е неправилен.',
  book: 'Книга',
  sign_up_2: 'Създай профил',
  log_in: 'Впиши се',
  your_email_address: 'Вашата електронна поща',
  select: 'Изберете',
  filters: 'Филтри',
  expand: 'Разширяване',
  collapse: 'Свиване',
  bookings_drawer_title: 'Изберете филтри, за да покажете само резервациите, които търсите.',
  clear_filters: 'Нулирайте филтрите',
  show_more: 'Покажи повече',
  dont_you_remember_password: 'Забравена парола?',
  password_information: 'Паролата трябва да се състои от поне 8 знака, да съдържа главна буква, цифра и специален знак.',
  forgotten_password_button: 'Изпратете линк за промяна на паролата',
  do_you_already_have_an_account: 'Имате ли вече акаунт?',
  reset_password_title: 'Задайте нова парола',
  reset_password_subtitle: 'Въведете новата си парола по-долу.',
  reset_password_button: 'Запазете новата парола',
  profile: 'Профил',
  logout: 'Отписване',
  cancel_booking: 'Отменете резервацията си',
  status_approved: 'Одобрено',
  status_payment: 'Очаква плащане',
  status_done: 'Завършено',
  status_cancelled: 'Отменен',
  cancel_booking_dialog_title: 'Искате ли да отмените резервацията си?',
  cancel_booking_dialog_information: 'Това действие не може да бъде отменено!',
  cancel_booking_dialog_button: 'Да, анулира резервацията ми',
  my_profile: 'Моят профил',
  my_data: 'Моите данни',
  upload_new_photo: 'Добавяне на нова снимка',
  birthday: 'рожден ден',
  save_changes: 'Запазите промените',
  phone_number: 'Телефонен номер',
  directional: 'Код на населеното място',
  save_new_password: 'Запазете новата парола',
  new_password: 'Нова парола',
  password_change: 'Промяна на паролата',
  view: 'Преглед',
  services_2: 'Услуги',
  accept_terms_and_conditions_1: 'Щраквайки върху опцията по-долу, аз се съгласявам с',
  accept_terms_and_conditions_2: 'Правила и условия',
  accept_terms_and_conditions_3: 'и потвърждавам, че съм прочел',
  accept_terms_and_conditions_4: 'Декларация за поверителност.',
  to: 'Да се',
  pln: '@{value} PLN',
  usd: '$@{value}',
  eur: '@{value} EUR',
  gbp: '£@{value}',
  jpy: '@{value} JPY',
  aud: '@{value} AUD',
  cad: '@{value} CAD',
  chf: '@{value} CHF',
  sek: '@{value} SEK',
  nzd: '@{value} NZD',
  brl: '@{value} BRL',
  bgn: '@{value} лв',
  czk: '@{value} CZK',
  dkk: '@{value} DKK',
  hkd: '@{value} HKD',
  huf: '@{value} HUF',
  ils: '@{value} ILS',
  myr: '@{value} MYR',
  mxn: '@{value} MXN',
  twd: '@{value} TWD',
  nok: '@{value} NOK',
  php: '@{value} PHP',
  ron: '@{value} RON',
  rub: '@{value} RUB',
  sgd: '@{value} SGD',
  thb: '@{value} THB',
  uah: '@{value} UAH',
  inr: '@{value} INR',
  clp: '@{value} CLP',
  login: 'Впиши се',
  email_is_incorrect: 'Имейл адресът е неправилен.',
  logged_out: 'Вие сте излезли',
  booking_dialog_date_is_already_taken: 'Избраният час не е наличен. Бихте ли избрали друго място?',
  data_search: 'Търсене на данни. Моля Изчакай...',
  no_employees: 'Без служители',
  no_services: 'Без услуги',
  no_categories: 'Няма категории',
  no_bookings: 'Резервациите не са намерени.',
  no_subscriptions: 'Няма намерени абонаменти.',
  female: 'Женски пол',
  male: 'Мъжки',
  other: 'Други',
  no_numeric_in_password: 'Няма цифров знак в паролата.',
  no_lowercase_in_password: 'Без малка буква в паролата.',
  no_uppercase_in_password: 'Без главна буква в паролата.',
  no_special_character: 'Без специален характер.',
  min_n_characters: 'Минималният брой знаци е @{min}.',
  max_n_characters: 'Максималният брой знаци е @{max}.',
  email: 'Електронна поща',
  reset_password_fail_text: 'Паролата не е променена. Връзката вероятно е изтекла.',
  reset_password_success_title: 'Вашата парола е сменена.',
  reset_password_success_text: 'Можете да влезете с новата си парола.',
  account_activation: 'Активиране на акаунт',
  account_activation_success: 'Акаунтът е активиран.',
  account_activation_fail: 'Имаше проблем при активирането на вашия акаунт. Изглежда, че акаунтът вече е активен или връзката вече не е валидна.',
  account_activation_processing: 'Активиране на вашия акаунт. Моля Изчакай...',
  account_activation_resend_title: 'Акаунтът не е активиран',
  account_activation_resend_text: 'Активирайте акаунта си, като щракнете върху връзката за активиране в имейла, който ви изпратихме.',
  account_activation_resend_button: 'Изпрати отново',
  confirm_send_activate_link_dialog_subtitle: 'Изпратихме имейл с връзката за активиране на имейл адреса, предоставен по-рано.',
  file_is_too_large: 'Избраният файл е твърде голям.',
  appointment_title: 'Изберете човек, дата и час на резервация',
  others: 'Други',
  countryLabel: 'Държава',
  requiredMessages: 'Изисква се телефонен номер',
  date_of_visit: 'Дата',
  hour_of_visit: 'време',
  thank_you_for_booking: 'Вашата резервация',
  no_free_hours: 'Няма налични часови интервали за избрания ден.',
  any_person: 'Случаен избор',
  password_changed: 'Вашата парола е сменена',
  incorrect_old_password: 'Старата парола е неправилна',
  error404: 'Грешка 404',
  minutes: 'мин',
  hours: 'ч',
  days: 'д',
  ok: 'Добре',
  web_page_temporary_disabled: 'Съжалявам! Тази страница беше временно деактивирана.',
  profile_update_success: 'Вашите данни са запазени.',
  form_is_invalid: 'Формулярът съдържа грешки. Моля, проверете маркираните полета и опитайте отново.',
  search: 'Търсене',
  pay_online_button_title: 'Плащане онлайн',
  account_not_activated_info: 'Имейл адресът не е потвърден. Потвърдете своя имейл адрес, като щракнете върху връзката в изпратеното съобщение.',
  paid: 'Платено',
  payments: 'Плащания',
  payment_service: 'Обслужване',
  payment_date: 'Дата',
  payment_price: 'Цена',
  payment_success_title: 'Плащането беше успешно, благодаря!',
  payment_fail_title: 'Плащането е неуспешно, моля опитайте отново.',
  pay_again: 'Платете отново',
  newsletter_subscribe_success: 'Благодаря ти! Вашият имейл адрес е потвърден.',
  newsletter_subscribe_fail: 'Имаше проблем при активирането на вашия имейл адрес. Изглежда, че имейл адресът вече е активен или връзката вече не е валидна.',
  newsletter_send_again: 'Изпрати отново',
  newsletter_send_again_success: 'Линкът за активиране е изпратен отново.',
  send_again_fail: 'Възникна проблем при генерирането на нова връзка за активиране, може би вашият имейл адрес вече е активен или току-що сте генерирали връзката.',
  newsletter_email_is_used: 'Имейл адресът вече е в списъка, ако адресът не е потвърден, можем да изпратим отново връзката за активиране.',
  newsletter_unsubscribe_success: 'Благодаря ти! Вашият имейл адрес е премахнат.',
  newsletter_unsubscribe_fail: 'Възникна проблем при изтриването на имейл адреса. Изглежда, че имейл адресът вече е изтрит или връзката е изтекла.',
  booking_payments: 'Резервации',
  simple_store_product_transactions: 'Продукти',
  payment_type_select_box: 'Изберете вида на плащането',
  payment_description: 'Описание',
  add_to_cart: 'Добави в кошницата',
  remove_from_cart: 'Извадете от количката',
  save: 'Запазване',
  bookings_cart_summary: 'Резюме на резервацията',
  today: 'Днес',
  fill_booking_details_title: 'Въведете подробности',
  create_an_account_question: 'Създайте акаунт и изпратете паролата на имейл адреса',
  value_is_incorrect: 'Тази стойност не е валидна.',
  first_available_date: 'Наличните времеви интервали са включени',
  service_location_1: 'Адрес',
  service_location_2: 'Телефон',
  service_location_3: 'Google Meet',
  service_location_4: 'Други',
  service_location_5: 'Zoom',
  service_location_6: 'Microsoft Teams',
  service_location_7: 'Skype',
  service_location_8: 'WhatsApp',
  service_location: 'Местоположение',
  booking_not_found: 'Резервацията не е намерена.',
  booking_canceled: 'Резервацията беше анулирана.',
  booking_canceled_fail: 'Тази резервация не може да бъде анулирана.',
  day: 'Ден',
  week: 'седмица',
  month: 'месец',
  year: 'година',
  booking_plural_1: 'Резервация',
  booking_plural_2: 'Резервации',
  booking_plural_3: 'Резервации',
  booking_plural_4: 'Резервации',
  booking_plural_5: 'Резервации',
  day_plural_1: 'Ден',
  day_plural_2: 'Дни',
  day_plural_3: 'Дни',
  day_plural_4: 'Дни',
  day_plural_5: 'Дни',
  week_plural_1: 'седмица',
  week_plural_2: 'седмици',
  week_plural_3: 'седмици',
  week_plural_4: 'седмици',
  week_plural_5: 'седмици',
  month_plural_1: 'месец',
  month_plural_2: 'месеци',
  month_plural_3: 'месеци',
  month_plural_4: 'месеци',
  month_plural_5: 'месеци',
  year_plural_1: 'година',
  year_plural_2: 'години',
  year_plural_3: 'години',
  year_plural_4: 'години',
  year_plural_5: 'години',
  tax: 'ДДС ID',
  tax_prefix: 'Префикс',
  get_company_data: 'Търсене',
  get_company_data_failed: 'Данните не можаха да бъдат изтеглени.',
  company_name: 'Име на компанията',
  street_and_number: 'Улица и номер',
  postcode: 'Пощенски код',
  city: 'град',
  country: 'Държава',
  subscribe: 'Абонирай се',
  recurrent_fee_info: 'Таксуван всеки',
  one_time_fee_info: 'Еднократна такса.',
  new_account_title: 'Създаване на нов акаунт',
  payment_data_title: 'Подробности на плащане',
  billing_data_checkbox_question: 'Данни за фактуриране',
  confirmation_modal_header: 'Действието изисква потвърждение',
  confirmation_modal_warning: 'Сигурен ли си, че искаш да продължиш?',
  no_way_to_undo_action: 'Това действие не може да бъде отменено!',
  delete_payment_method: 'Премахнете картата',
  add_payment_method: 'Добавете карта',
  fill_card_details: 'Попълнете данните за плащане.',
  subscriptions: 'Абонаменти',
  subscription: 'Абонамент',
  invoice_number: 'Фактура',
  number: 'Номер',
  status: 'Статус',
  details: 'Подробности',
  created_at: 'Създаден',
  subscription_status_initiated: 'Неплатени',
  subscription_status_active: 'Активен',
  subscription_status_inactive: 'Неактивен',
  subscription_status_canceled: 'Отменен',
  ends_at: 'Завършва в',
  ends_at_default: 'Валидна до края на отчетния период',
  select_subscription_to_pay: 'Изберете абонамент за плащане.',
  pay_with_subscription: 'Плащане с абонамент',
  bookings_paid_by_subscription_success_message: 'Платено',
  bookings_not_paid_by_subscription_error_message: 'Резервацията не може да бъде платена с избрания абонамент.',
  stripe: 'Ивица',
  provider_tag: 'Метод',
  card: 'карта',
  cash: 'Пари в брой',
  transfer: 'Трансфер',
  promo_code_label: 'Код за отстъпка',
  promo_code_invalid_message: 'Предоставеният код за отстъпка е невалиден.',
  payment_method_setup_error: 'Имаше проблем с вашата карта. Вашата банка е отказала да оторизира картата за по-късни транзакции. Моля, свържете се с вашата банка.',
  active_subscription_missing: 'Резервацията за тази услуга изисква активен абонамент. За съжаление нямате абонамент за използване.',
  area_code: 'Код на населеното място',
  usage: 'Използване',
  create_account_in_stripe_failed: 'Платежният оператор отхвърли вашите абонаментни данни. Моля, проверете коректността на формуляра (данъчен номер, телефонен номер, имейл адрес).',
  additional_auth_required: 'Вашата банка изисква допълнително разрешение',
  general_payment_error: 'Имаше проблем с вашето плащане. Може би вашата карта не поддържа абонаменти, има лимити за покупки или няма достатъчно средства за плащане на абонамента. Моля, свържете се с вашата банка или изберете друг метод на плащане.',
  subscription_set_successfully: 'Абонаментът е настроен правилно.',
  booking_created: 'Нова резервация',
  booking_updated: 'Промяна на резервацията',
  booking_deleted: 'Изтриване на резервация',
  booking_reminder: 'Напомняне за резервация',
  email_notifications: 'Имейл известия',
  sms_notifications: 'SMS известия',
  save_user_preferences: 'Запазете предпочитанията',
  free_spots: 'Останали петна',
  no: 'Не',
  yes: 'Да',
  p24_inactive: 'Платежният оператор Przelewy24 не може да се използва. Моля, свържете се с вашия платежен оператор.',
  stripe_customer_invalid: 'Невалиден клиентски идентификатор на Stripe. Методът на плащане не може да бъде прикрепен към клиента.',
  stripe_resource_invalid: 'Избраният продукт не е наличен. Моля, проверете наличността на продукта при платежния оператор.',
  account_blocked_message: 'Вашият акаунт е неактивен. Моля, свържете се с администратора.',
  time_zone: 'Часова зона',
  no_spots_left: 'Достигнахте максималния брой налични места.',
  employee_auth_required: 'Моля, влезте в акаунта си или използвайте друг имейл адрес. Акаунтите на служители изискват влизане.',
  subpage_auth_required: 'Моля, влезте в акаунта си, за да видите раздела.',
  gross: 'Брутна сума.',
  subscription_login_info: 'Вече имате акаунт? Моля, влезте по-долу, за да попълните вашите данни.',
  change_date_time: 'Пренасрочване',
  promo_code_checkbox: 'Имам код за отстъпка',
  booking_promo_code_label: 'Въведете кода за отстъпка',
  select_hour: 'Изберете време',
  id: 'Документ за самоличност',
  booking_rescheduled: 'Вашата резервация е променена.',
  booking_rescheduled_fail: 'Тази резервация не може да бъде променена.',
  max_user_bookings_error: 'Надхвърлихте максималния брой резервации. Моля изберете друга услуга или се свържете с нас.',
  in_total: 'Обща сума',
  company: 'Компания',
  individual: 'Частно лице',
  bulk_payment: 'Групово плащане',
  make_bulk_payment: 'Групово плащане',
  simple_store_product_confirmation_title: 'Попълнете формуляра за покупка',
  select_service_type: 'Изберете опция за услуга',
  add_to_calendar_button_label: 'Добавяне към календара',
  login_to_use_service_with_subscription: 'Моля, влезте в акаунта си, за да запазите час за тази услуга.',
  booking_dialog_date_is_already_taken_multiple_bookings: 'Избраната дата не е налична. Моля, изберете друга свободна дата. При резервация на няколко дати се уверете, че датите не се противоречат една на друга.',
  select_location_calendar_warning: 'Изберете местоположение, за да заредите наличните часове.',
  selectable_location_online_title: 'Онлайн',
  change: 'Промени',
  show_less_hours_button: 'Покажи по-малко часове',
  show_more_hours_button: 'Покажи повече часове',
  add_another_button_title: 'Добави още',
  close: 'Затвори',
  selected_time_slots: 'Избрани времеви слотове',
  select_location: 'Изберете местоположение',
  captcha_invalid_error_notification: 'Невалидна Captcha проверка. Опитайте да презаредите страницата, ако проблемът продължава.',
  verify_code_incorrect_error: 'Въведеният код е невалиден.',
  verify_code_too_many_requests: 'Не можем да генерираме нов код в момента. Опитайте отново по-късно или използвайте кода, който вече сме изпратили.',
  send_again_button_title: 'Изпратете отново',
  verify_title: 'Потвърдете',
  verify_code_title: 'Въведете потвърдителен код',
  verify_code_description: 'Изпратихме 6-цифрен потвърдителен код на вашия имейл. Моля, въведете го по-долу:',
  verification_invalid_error_notification: 'Невалидна проверка. Опитайте отново по-късно.'
}
